<template>
  <section
    class="loginform d-flex justify-content-center align-items-center m-auto"
  >
    <div class="my-5">
      <img class="mb-4" src="../../assets/img/header__logo.svg" />
      <h1 class="my-4">Забыли пароль?</h1>
      <div
        class="form d-flex flex-column align-items-center justify-content-center m-auto"
      >
        <h4>
          Введите email, привязанный к вашему аккаунту. На этот email будет
          отправлено письмо для восстановления пароля.
        </h4>
        <input
          class="mt-4"
          type="email"
          placeholder="Ваш Email"
          ref="input"
          @change="sendToEmail"
          required
        />
        <button class="mt-4">Подтвердить</button>
      </div>
    </div>
    <!-- Global component for messages -->
    <modal-message v-if="!!message">
      <h2>{{ message }}</h2>
      <button @click="message = ''">ОК</button>
    </modal-message>
  </section>
</template>

<script>


export default {
  data() {
    return {
      message: ''
    }
  },
  methods: {
    async sendToEmail() {
      const email = this.$refs.input.value.trim();

      try {
        const responsePassword = await this.$axios.post(
          "https://back.rizaherb.kz/index.php?route=rest/forgotten/forgotten",
          {
            email,
          }
        );
        console.log(responsePassword);
        this.message = "Запрос о смене пароля был выслан вам на почту";
      } catch (err) {
        console.log(err.response);
        this.message = "Такой почты нету, среди зарегистрированных пользователей!";
      }
    },
  },
};
</script>

<style scoped>
.loginform {
  text-align: center;
  width: 100%;
  max-width: 400px;
}
.loginform h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;

  color: #000000;
}
.loginform .form {
  padding: 35px;
  border: 1px solid #75b051;
  box-sizing: border-box;
  border-radius: 8px;
}

.loginform .form h4 {
  font-size: 14px;
  color: #929292;
}
.loginform .form input {
  background: #ffffff;
  border: 1px solid #75b051;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  text-align: center;
}
.loginform .form button {
  background: #75b051;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
  width: 100%;
  padding: 10px;
  text-align: center;
}

@media (max-width: 375px) {
  .loginform {
    padding: 10px;
  }
  .loginform .form {
    padding: 20px;
  }
}
</style>
